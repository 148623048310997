import { pathname as getPathname } from './path';
export function pathname(path) {
    return getPathname(stripQuery(path));
}
function stripQuery(path) {
    var questionIdx = path.indexOf('?');
    if (questionIdx !== -1) {
        path = path.substring(0, questionIdx);
    }
    return path;
}
function stripHash(path) {
    var hashIdx = path.indexOf('#');
    if (hashIdx !== -1) {
        path = path.substring(0, hashIdx);
    }
    return path;
}
