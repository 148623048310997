import 'reflect-metadata'

import { extendRouter, reload as reloadRouter } from '@rc/vue-router'

import { bootstrap } from '@/app/bootstrap'

bootstrap({
  pre: router => extendRouter(router),

  post: router => reloadRouter(router),
})
