import { assign } from './assign';
export var omit = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    var object = args.shift();
    var props = args;
    var result = assign({}, object);
    for (var _a = 0, props_1 = props; _a < props_1.length; _a++) {
        var prop = props_1[_a];
        if (Array.isArray(prop)) {
            for (var _b = 0, prop_1 = prop; _b < prop_1.length; _b++) {
                var key = prop_1[_b];
                delete result[key];
            }
        }
        else {
            delete result[prop];
        }
    }
    return result;
};
