import { TabbarViewProvider } from '@/pages/misc/tabbar'
import { ProductDetailViewProvider } from '@/pages/product/detail'

export
const routes = [
  {
    path: '/index',

    children: [
      {
        name: 'home',
        asset: {
          providers: {
            Tabbar: TabbarViewProvider,
          }
        }
      },

      {
        name: 'activities',
        asset: {
          providers: {
            Tabbar: TabbarViewProvider,
          }
        }
      },

      {
        name: 'service',
        asset: {
          providers: {
            Tabbar: TabbarViewProvider,
          }
        }
      },

      {
        name: 'information',
        asset: {
          providers: {
            Tabbar: TabbarViewProvider,
          }
        }
      },

      {
        name: 'center',
        asset: {
          providers: {
            Tabbar: TabbarViewProvider,
          }
        }
      },

      {
        name: 'recommend',
        asset: {
          providers: {
            Tabbar: TabbarViewProvider,
          }
        }
      },

      {
        name: 'productDetail',
        provider: ProductDetailViewProvider,
      }
    ]
  }
]
