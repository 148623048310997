import { Component } from '@/commons/component/decorators'

export
function TabbarViewProvider(BaseComponent) {
  @Component()
  class TabbarView extends BaseComponent {
    /** @override */
    handleTab(value) {
      if (Number(value) === 3) {
        window.location.href = process.env.CONTACT_URL

        return
      }

      super.handleTab(value)
    }
  }

  return TabbarView
}
