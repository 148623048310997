export function createArrayIterator(array) {
    if (array[Symbol === null || Symbol === void 0 ? void 0 : Symbol.iterator]) {
        return array[Symbol.iterator]();
    }
    var i = 0;
    return {
        next: function () {
            while (i < array.length) {
                return { done: false, value: array[i++] };
            }
            return { done: true, value: undefined };
        }
    };
}
