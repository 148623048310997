var hasOwn = function (object, key) { return Object.prototype.hasOwnProperty.call(object, key); };
export var pick = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    var result = {};
    var object = args.shift();
    var props = args;
    for (var _a = 0, props_1 = props; _a < props_1.length; _a++) {
        var prop = props_1[_a];
        if (Array.isArray(prop)) {
            for (var _b = 0, prop_1 = prop; _b < prop_1.length; _b++) {
                var key = prop_1[_b];
                if (hasOwn(object, key)) {
                    // @ts-ignore
                    result[key] = object[key];
                }
            }
        }
        else {
            if (hasOwn(object, prop)) {
                // @ts-ignore
                result[prop] = object[prop];
            }
        }
    }
    return result;
};
