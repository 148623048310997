import { __spreadArray } from "tslib";
export var assign = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    if (args.length < 2) {
        return args[0];
    }
    var target = args.shift();
    var sources = args;
    if (!target || typeof target !== 'object') {
        return target;
    }
    if (typeof Object.assign === 'function') {
        return Object.assign.apply(Object, __spreadArray([target], sources, false));
    }
    for (var _a = 0, sources_1 = sources; _a < sources_1.length; _a++) {
        var source = sources_1[_a];
        if (!source || typeof source !== 'object') {
            continue;
        }
        for (var _b = 0, _c = Object.keys(source); _b < _c.length; _b++) {
            var key = _c[_b];
            target[key] = source[key];
        }
    }
    return target;
};
