import { createArrayIterator } from './createArrayIterator';
export function createTreeIterator(root) {
    var stacks = [];
    var stage = 0 /* IteratorStage.Enter */;
    return {
        next: function () {
            while (true) {
                if (stage === -1 /* IteratorStage.Exit */) {
                    return { done: true, value: undefined };
                }
                if (stage === 0 /* IteratorStage.Enter */) {
                    if (root.children) {
                        stage = 1 /* IteratorStage.BeforeVisit */;
                        stacks.push(createArrayIterator(root.children));
                    }
                    else {
                        stage = -1 /* IteratorStage.Exit */;
                    }
                    return { done: false, value: root };
                }
                if (stage === 1 /* IteratorStage.BeforeVisit */) {
                    var iterator = stacks[stacks.length - 1];
                    var _a = iterator.next(), done = _a.done, current = _a.value;
                    if (done) {
                        stage = 2 /* IteratorStage.AfterVisit */;
                        continue;
                    }
                    if (current.children) {
                        stage = 1 /* IteratorStage.BeforeVisit */;
                        stacks.push(createArrayIterator(current.children));
                    }
                    else {
                        stage = 2 /* IteratorStage.AfterVisit */;
                    }
                    return { done: false, value: current };
                }
                if (stage === 2 /* IteratorStage.AfterVisit */) {
                    stacks.pop();
                    if (!stacks.length) {
                        stage = -1 /* IteratorStage.Exit */;
                    }
                    continue;
                }
                // default
                return { done: true, value: undefined };
            }
        }
    };
}
