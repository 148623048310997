export var includes = function (array, target, fromIndex) {
    if (fromIndex === void 0) { fromIndex = 0; }
    if (Array.isArray(array)) {
        for (var i = fromIndex; i < array.length; i += 1) {
            if (target === array[i]) {
                return true;
            }
        }
    }
    return false;
};
