import { toPath } from './toPath';
var hasOwn = Object.prototype.hasOwnProperty;
export var has = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    var target = args[0], key = args[1], defaultValue = args[2];
    var result = target;
    for (var _a = 0, _b = toPath(key); _a < _b.length; _a++) {
        var segment = _b[_a];
        if (result == null) {
            return false;
        }
        if (!hasOwn.call(result, segment)) {
            return false;
        }
        result = result[segment];
    }
    return true;
};
