var ThunkState;
(function (ThunkState) {
    ThunkState[ThunkState["Pending"] = 0] = "Pending";
    ThunkState[ThunkState["Fulfilled"] = 1] = "Fulfilled";
    ThunkState[ThunkState["Rejected"] = 2] = "Rejected";
})(ThunkState || (ThunkState = {}));
var DEFAULT_THUNK_ERROR = new Error('thunk rejected');
export function thunk(exeuctor) {
    var value;
    var reason;
    var state = ThunkState.Pending;
    var handlers;
    try {
        exeuctor(function (v) {
            state || (state = ThunkState.Fulfilled);
            value = v;
            notify(null, value);
        }, function (r) {
            state || (state = ThunkState.Rejected);
            reason = r !== null && r !== void 0 ? r : DEFAULT_THUNK_ERROR;
            notify(reason);
        });
    }
    catch (e) {
        state || (state = ThunkState.Rejected);
        reason = e;
        notify(reason);
    }
    return function (callback) {
        switch (state) {
            case ThunkState.Rejected:
                callback(reason);
                break;
            case ThunkState.Fulfilled:
                callback(null, value);
                break;
            default:
                (handlers !== null && handlers !== void 0 ? handlers : (handlers = [])).push(callback);
                break;
        }
    };
    function notify(arg1, arg2) {
        if (handlers) {
            for (var _i = 0, handlers_1 = handlers; _i < handlers_1.length; _i++) {
                var handler = handlers_1[_i];
                handler === null || handler === void 0 ? void 0 : handler(arg1, arg2);
            }
            handlers.length = 0;
            handlers = undefined;
        }
    }
}
thunk.resolve = thunkResolve;
thunk.reject = thunkReject;
export function thunkResolve(value) {
    return function (callback) { return callback(null, value); };
}
export function thunkReject(reason) {
    if (reason === void 0) { reason = DEFAULT_THUNK_ERROR; }
    return function (callback) { return callback(reason); };
}
