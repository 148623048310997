import { __spreadArray } from "tslib";
export var defaults = function () {
    var _a;
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    if (args.length < 2) {
        return args[0];
    }
    var target = args.shift();
    var sources = args;
    if (sources.length === 0) {
        return target;
    }
    if (!target || typeof target !== 'object') {
        return target;
    }
    if (typeof Object.assign === 'function') {
        if (sources.length > 1) {
            sources = [Object.assign.apply(Object, __spreadArray([{}], sources, false))];
        }
    }
    for (var _b = 0, sources_1 = sources; _b < sources_1.length; _b++) {
        var source = sources_1[_b];
        if (!source || typeof source !== 'object') {
            continue;
        }
        for (var _c = 0, _d = Object.keys(source); _c < _d.length; _c++) {
            var key = _d[_c];
            (_a = target[key]) !== null && _a !== void 0 ? _a : (target[key] = source[key]);
        }
    }
    return target;
};
