var noop = function () { };
export function intercept(target, propertyKey, handlers) {
    var _a, _b, _c;
    var prevDesc = Object.getOwnPropertyDescriptor(target, propertyKey);
    if (prevDesc && !prevDesc.configurable) {
        return noop;
    }
    Object.defineProperty(target, propertyKey, {
        get: (handlers.get ?
            function () {
                var $super = {
                    get: function (target) { var _a; return (_a = prevDesc === null || prevDesc === void 0 ? void 0 : prevDesc.get) === null || _a === void 0 ? void 0 : _a.call(target); }
                };
                return handlers.get(this, $super);
            } :
            (_a = prevDesc === null || prevDesc === void 0 ? void 0 : prevDesc.get) !== null && _a !== void 0 ? _a : noop),
        set: (handlers.set ?
            function (value) {
                var $super = {
                    set: function (target, value) { var _a; return (_a = prevDesc === null || prevDesc === void 0 ? void 0 : prevDesc.set) === null || _a === void 0 ? void 0 : _a.call(target, value); }
                };
                handlers.set(this, value, $super);
            } :
            (_b = prevDesc === null || prevDesc === void 0 ? void 0 : prevDesc.set) !== null && _b !== void 0 ? _b : noop),
        enumerable: (_c = prevDesc === null || prevDesc === void 0 ? void 0 : prevDesc.enumerable) !== null && _c !== void 0 ? _c : false,
        configurable: true,
    });
    return function () {
        // reset
        if (prevDesc) {
            Object.defineProperty(target, propertyKey, prevDesc);
        }
        else {
            // @ts-ignore
            delete target[propertyKey];
        }
    };
}
