import { findLastIndex } from './findLastIndex';
export function join() {
    var paths = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        paths[_i] = arguments[_i];
    }
    paths = paths.filter(Boolean);
    var startIdx = findLastIndex(paths, function (path) { return /^\//.test(path); });
    if (startIdx !== -1) {
        paths = paths.slice(startIdx);
    }
    return normalize(paths.join('/'));
}
export function normalize(path) {
    var absolute = isAbsolute(path);
    path = (path
        .replace(/\/{2,}/g, '') /* adjacent slash */
        .replace(/(?:\.\/)+/g, '') /* adjacent dot slash */
        .replace(/\/+$/, '') /* trailing slash */);
    if (absolute) {
        if (!isAbsolute(path)) {
            return "/".concat(path);
        }
    }
    return path;
    // not use look behind for compatibility
    // return (
    //   path
    //     .replace(/(?<=\/)\/+/g, '') /* adjacent slash */
    //     .replace(/(?:\.\/)+/g, '') /* adjacent dot slash */
    //     .replace(/(?<!^)\/+$/, '') /* trailing slash */
    // )
}
export function pathname(path) {
    return normalize(path);
}
export function dirname(path) {
    path = pathname(path);
    var slashIdx = path.lastIndexOf('/');
    if (slashIdx === -1) {
        return path;
    }
    if (slashIdx === 0) {
        return '/';
    }
    return path.substring(0, slashIdx);
}
export function isAbsolute(path) {
    return /^\//.test(path);
}
