export var findLastIndex = function (array, predicate) {
    if (typeof predicate !== 'function') {
        return -1;
    }
    if (!Array.isArray(array)) {
        return -1;
    }
    for (var i = array.length - 1; i >= 0; i -= 1) {
        if (predicate(array[i], i, array)) {
            return i;
        }
    }
    return -1;
};
