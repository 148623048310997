export var castArray = function (arg) {
    var value = arg;
    if (!value || typeof value !== 'object') {
        return [value];
    }
    if (Array.isArray(value)) {
        return value;
    }
    if ('length' in value && typeof value.length === 'number') {
        return Array.from(value);
    }
    // @ts-ignore
    if (Symbol.iterator in value && typeof value[Symbol.iterator] === 'function') {
        return Array.from(value);
    }
    return [value];
};
