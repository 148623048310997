export var last = function (arg) {
    var array = arg;
    if (Array.isArray(array)) {
        return array[array.length - 1];
    }
    if (array && 'length' in array) {
        if (typeof array.length === 'number') {
            array = Array.from(array);
            return array[array.length - 1];
        }
    }
    if (array && 'size' in array) {
        // @ts-ignore
        if (typeof array.size === 'number') {
            array = Array.from(array);
            return array[array.length - 1];
        }
    }
    return undefined;
};
