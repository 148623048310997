import { Component } from '@/commons/component/decorators'

export
function ProductDetailViewProvider(BaseComponent) {
  @Component()
  class ProductDetailView extends BaseComponent {
    // @override
    toService() {
      window.location.href = process.env.CONTACT_URL
    }
  }

  return ProductDetailView
}
