export function clone(value) {
    try {
        return JSON.parse(JSON.stringify(value));
    }
    catch (error) {
        if (Array.isArray(value)) {
            return [];
        }
        return {};
    }
}
