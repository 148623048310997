import { identity } from './identity';
export var mapValues = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    var object = args[0], _a = args[1], customizer = _a === void 0 ? identity : _a;
    if (!object || typeof object !== 'object') {
        return {};
    }
    var result = {};
    for (var _b = 0, _c = Object.keys(object); _b < _c.length; _b++) {
        var key = _c[_b];
        result[key] = customizer(object[key], key, object);
    }
    return result;
};
